/*
 * Brand Style Rules
 * Override theme styles
*/
/* COLORS */
/* FONTS */
/* COMMON */
/* GRID */
/* CONTAINER */
/* BUTTONS AND CONTROLS */
/* TYPE */
:root {
  --primary-color: #538849;
  --secondary-color: #425719;
  --footer-color: #fafbfd;
  --light-color: #fff;
  --border-color: #e1e1e1;
  --dark-color: #656565;
  --darker-color: #333;
  --gray: gray;
  --header-desktop-height: 56px;
  --header-desktop-height-wrapper: 141px;
  --header-mobile-height: 60px; }

body {
  /*
  font-family: 'Roboto', Helvetica, Lato, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.5;
  color: #000;
  */ }

.devc {
  display: none; }

a, a:hover, .btn-link, .btn-link:hover {
  color: var(--primary-color); }

a:hover, a:focus, .btn-link:hover, .btn-link:focus {
  text-decoration: underline; }

::selection {
  background-color: var(--primary-color);
  color: #fff; }

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto',Helvetica,Roboto,Arial,sans-serif; }

@media (min-width: 992px) {
  .header {
    height: var(--header-desktop-height-wrapper); } }

.header-top {
  background-color: var(--light-color); }

.header-price {
  padding: 10px 0;
  color: white; }
  @media (max-width: 991.98px) {
    .header-price {
      height: 40px !important; } }
  .header-price .qmod-ch-up {
    color: #00ff19; }
  .header-price .qmod-ch-down {
    color: #ffaaaa; }

.header-logoLine {
  width: 100%;
  /*
  .main_menu {
    @include media-breakpoint-down(md) {
      display: none;
    }
    a {
      color: var(--gray);
    }
    
    .av-main-nav li:hover > ul {
      list-style-type: none;
      display: block;
      border-top: 2px var(--primary-color) solid;
      background-color: #ffffff;
      color: var(--gray);
      a {
        width: 100%;
        height: auto;
        float: left;
        text-align: left;
        line-height: 23px;
        padding: 8px 15px;
        font-size: 12px;
        min-height: 23px;
        max-width: none;
        text-decoration: none;
        // font-family: inherit;
        &:hover {
          background-color: #f8f8f8;
          color: var(--darker-color);
        }
      }
    }
    
  }
  */ }
  .header-logoLine .container {
    /*
    padding-left: 10px;
    padding-right: 10px;
    */ }
    @media (min-width: 992px) {
      .header-logoLine .container {
        height: var(--header-desktop-height);
        line-height: var(--header-desktop-height); } }
    @media (max-width: 991.98px) {
      .header-logoLine .container {
        height: var(--header-mobile-height);
        line-height: var(--header-mobile-height); } }
  .header-logoLine .inner-container {
    height: 100%;
    width: 100%;
    position: relative; }
  @media (max-width: 991.98px) {
    .header-logoLine .logo {
      float: none;
      position: static; }
      .header-logoLine .logo img {
        margin: 0 auto;
        /*height: 60px;*/ } }

.header-nav {
  padding-top: 5px; }

.header-navLine {
  padding: 0;
  width: 100%; }
  .header-navLine .nav-caller {
    color: var(--primary-color); }
  .header-navLine a:before {
    color: var(--primary-color) !important; }
  @media (min-width: 992px) {
    .header-navLine .nav [data-level='1'] > li > a:focus,
    .header-navLine .nav [data-level='1'] > li > a:hover {
      background: var(--primary-color) !important; }
    .header-navLine .nav [data-level='0'] > li > a:focus,
    .header-navLine .nav [data-level='0'] > li > a:hover {
      color: var(--primary-color) !important; } }

@media (min-width: 992px) {
  .main-nav li.menu-item-has-children > a {
    padding-right: 14px;
    background-position: right 5px; } }

@media (max-width: 991.98px) {
  .nav [data-level='0'] > li.focus > ul,
  .nav [data-level='0'] > li:hover > ul {
    border-top: 2px solid var(--primary-color);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1); }
  .nav [data-level='0'] > li > a:focus,
  .nav [data-level='0'] > li > a:hover {
    color: var(--primary-color); }
  .nav [data-level='1'] > li > a {
    color: var(--dark-color); }
  .nav [data-level='1'] > li > a:hover,
  .nav [data-level='1'] > li > a:focus {
    background-color: var(--light-color);
    text-decoration: none;
    color: #000; } }

.breadcrumbs {
  background-color: var(--primary-color); }
  .breadcrumbs span {
    color: white; }

.breadcrumbs ol li:not(:last-child):after,
.breadcrumbs ul li:not(:last-child):after {
  display: none; }

.carousel-wrapper {
  background-color: var(--dark-color); }

.content {
  background-color: var(--light-color); }

.footer {
  background-color: var(--footer-color); }

.footer-content .logo img {
  margin-bottom: 4px; }

.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color); }

.btn-primary:hover, .btn-primary:focus {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color); }

.form-control:focus,
.indexBlock-loadMore a .icon,
.plainlist .icon {
  border-color: var(--primary-color); }

/* QMODS */
.qtool .qmod-ui-tool a,
.qtool .qmod-btn.qmod-btn-link {
  color: var(--primary-color);
  text-decoration: none; }

.qtool .qmod-ui-tool a:hover,
.qtool .qmod-ui-tool a:focus,
.qtool .qmod-btn.qmod-btn-link:hover,
.qtool .qmod-btn.qmod-btn-link:focus {
  text-decoration: underline; }

.qtool .qmod-btn.qmod-darkblue,
.qtool .qmod-btn.qmod-darkblue:hover,
.qtool .qmod-btn.qmod-green,
.qtool .qmod-btn.qmod-green:hover {
  background-color: var(--primary-color); }

/* Dropdown menu filters */
.qtool .qmod-dropdown-menu li > a {
  color: black; }

.qtool .qmod-dropdown-menu li > a:hover,
.qtool .qmod-dropdown-menu li > a:focus {
  background-color: var(--light-color);
  text-decoration: none; }

.qtool .qmod-dropdown-menu li > a.qmod-active {
  background-color: var(--primary-color); }

.qtool a.qmod-dropdown_toggle {
  background-color: white;
  color: black; }

/* SEC Filing */
.qtool .qmod-sec-filings .qmod-quotehead,
.qtool .qmod-sec-filings .qmod-heading.qmod-hbg {
  display: none; }

/* filter */
.qtool .qmod-sec-filings .qmod-modifiers .qmod-modifier:hover .qmod-dropdown_toggle {
  background: white; }

/* table */
.qtool .qmod-sec-filings .qmod-modifiers {
  padding: 0 0 20px; }

.qtool .qmod-sec-filings .dataTables_wrapper {
  margin-left: 0;
  margin-right: 0; }

.qtool .qmod-sec-filings .qmod-block-wrapper,
.qtool .qmod-sec-filings table.dataTable tbody tr {
  background-color: var(--light-color); }

/* pagination */
.qtool .qmod-sec-filings .qmod-pager-cont .qmod-pager-item {
  background-color: var(--light-color);
  color: var(--primary-color); }

.qtool .qmod-sec-filings .qmod-pager-cont .qmod-pager-item.qmod-pager-num:hover,
.qtool .qmod-sec-filings .qmod-pager-cont .qmod-pager-item.qmod-pager-num:focus {
  background-color: var(--light-color);
  color: var(--secondary-color); }

.qtool .qmod-sec-filings .qmod-pager-cont .qmod-pager-item.qmod-pager-active,
.qtool .qmod-sec-filings .qmod-pager-cont .qmod-pager-item.qmod-pager-active:hover,
.qtool .qmod-sec-filings .qmod-pager-cont .qmod-pager-item.qmod-pager-active:focus,
.qtool .qmod-pricehistory .dataTables_wrapper .dataTables_paginate .paginate_button:active,
.qtool .qmod-pricehistory .dataTables_wrapper .dataTables_paginate .paginate_button.current,
.qtool .qmod-pricehistory .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background-color: var(--light-color);
  color: black;
  font-weight: bold; }

.qtool .qmod-sec-filings .qmod-pager-cont .qmod-pager-item.qmod-pager-prev,
.qtool .qmod-sec-filings .qmod-pager-cont .qmod-pager-item.qmod-pager-next {
  background-color: var(--light-color);
  color: var(--primary-color); }

/* Detailed Stock Quote */
.qtool .qmod-detailedquote {
  margin-top: 20px; }

.qtool .qmod-detailedquote .qmod-heading.qmod-hbg {
  display: none; }

.qtool .qmod-detailedquote .qmod-quotehead .qmod-barpct {
  background-color: var(--primary-color); }

/* Interactive Chart */
.qtool .qmod-simplechart .qmod-heading.qmod-hbg {
  display: none; }

.qtool .qmod-simplechart.qmod-ui-tool .qmod-chart-display .qmod-legend > li,
.qtool .qmod-simplechart.qmod-ui-tool .qmod-chart-buttons-rs .qmod-legend > li,
.qtool .qmod-simplechart.qmod-ui-tool .qmod-chart-display .qmod-legend > li,
.qtool .qmod-simplechart.qmod-ui-tool .qmod-chart-buttons-rs .qmod-legend > li {
  background: white !important;
  color: var(--primary-color); }

.qtool .qmod-simplechart .highcharts-reset-zoom rect {
  fill: var(--primary-color); }

.qtool .qmod-simplechart.qmod-ui-tool .qmod-chart-display .qmod-legend > li.qmod-active,
.qtool .qmod-simplechart.qmod-ui-tool .qmod-chart-buttons-rs .qmod-legend > li.qmod-active,
.qtool .qmod-pricehistory .dataTables_wrapper .dataTables_paginate .paginate_button:active,
.qtool .qmod-pricehistory .dataTables_wrapper .dataTables_paginate .paginate_button.current,
.qtool .qmod-pricehistory .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background: white !important;
  color: black !important;
  font-weight: bold; }

.qtool .qmod-simplechart.qmod-ui-tool .qmod-chart-display .qmod-legend > li:hover,
.qtool .qmod-simplechart.qmod-ui-tool .qmod-chart-buttons-rs .qmod-legend > li:hover {
  background: white;
  color: var(--primary-color); }

.qtool .qmod-simplechart.qmod-ui-tool .qmod-chart-display .qmod-legend > li .qmod-dd-more .qmod-dd-more-ul,
.qtool .qmod-simplechart.qmod-ui-tool .qmod-chart-buttons-rs .qmod-legend > li .qmod-dd-more .qmod-dd-more-ul {
  background: var(--primary-color);
  color: white; }

.qtool .qmod-simplechart.qmod-ui-tool .qmod-chart-display .qmod-legend > li .qmod-dd-more .qmod-dd-more-item:hover:not(.qmod-list-header),
.qtool .qmod-simplechart.qmod-ui-tool .qmod-chart-buttons-rs .qmod-legend > li .qmod-dd-more .qmod-dd-more-item:hover:not(.qmod-list-header) {
  background: var(--secondary-color);
  color: white; }

.qtool .highcharts-series rect {
  fill: var(--primary-color); }

/* Price History */
.qtool .qmod-pricehistory .qmod-quotehead {
  display: none; }

/* Mini Quote */
.qtool .qmod-miniquotes .qmod-shortname {
  display: inline; }

.qtool .qmod-miniquotes .qmod-longname {
  display: none; }

#disclaimer {
  border-bottom-width: 0; }

.menu-button {
  margin-top: -25px; }

.mobile-nav#investor_center {
  border-bottom: 1px #425719 solid;
  margin-bottom: 10px;
  padding-bottom: 5px; }
